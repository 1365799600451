import React from 'react'
export default function Header() {
  return (
    <section className="section-py first-section-pt py-0 ">
      <div id="carouselExample" className="carousel slide" data-bs-ride="carousel" >
        <div className="carousel-indicators">
          <button type="button" data-bs-target="#carouselExample" data-bs-slide-to={0} className="active d-none" aria-current="true" aria-label="Slide 1" />
          <button type="button" data-bs-target="#carouselExample" data-bs-slide-to={1} className="d-none" aria-label="Slide 2" />
          <button type="button" data-bs-target="#carouselExample" data-bs-slide-to={2} className="d-none" aria-label="Slide 3" />
          <div className="input-wrapper my-3 input-group input-group-lg input-group-merge position-relative mx-auto">
          <span className="input-group-text" id="basic-addon1"><i className="bx bx-search bx-sm" /></span>
          <input type="text" className="form-control" placeholder="Hola, buscas algun producto?" aria-label="Hola, buscas algun producto?" aria-describedby="basic-addon1" />
        </div>
        </div>
        <div className="carousel-inner" style={{"height":"400px"}}>
          <div className="carousel-item active">
            <img className="d-block w-100" src="../../assets/img/elements/13.jpg" alt="First slide" style={{"height":"400px"}} />
            {/* <div className="carousel-caption d-none d-md-block">
              <h3>First slide</h3>
              <p>Eos mutat malis maluisset et, agam ancillae quo te, in vim congue pertinacia.</p>
            </div> */}
           
          </div>
          <div className="carousel-item">
            <img className="d-block w-100" src="../../assets/img/elements/2.jpg" alt="Second slide" style={{"height":"400px"}} />
            {/* <div className="carousel-caption d-none d-md-block">
              <h3>Second slide</h3>
              <p>In numquam omittam sea.</p>
            </div> */}
          </div>
          <div className="carousel-item">
            <img className="d-block w-100" src="../../assets/img/elements/18.jpg" alt="Third slide" style={{"height":"400px"}} />
            {/* <div className="carousel-caption d-none d-md-block">
              <h3>Third slide</h3>
              <p>Lorem ipsum dolor sit amet, virtute consequat ea qui, minim graeco mel no.</p>
            </div> */}
          </div>
        </div>
        
        <a className="carousel-control-prev" href="#carouselExample" role="button" data-bs-slide="prev">
          <span className="carousel-control-prev-icon" aria-hidden="true" />
          <span className="visually-hidden">Previous</span>
        </a>
        <a className="carousel-control-next" href="#carouselExample" role="button" data-bs-slide="next">
          <span className="carousel-control-next-icon" aria-hidden="true" />
          <span className="visually-hidden">Next</span>
        </a>
        
      </div>
      
    </section>
  )
}
