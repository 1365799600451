/* eslint-disable jsx-a11y/anchor-is-valid */

import React, { useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from "react-router-dom"

import Dashboard from "./views/Dashboard";
import Contenido from './views/Contenido';
import Principal from './views/Principal';
import Cont from './views/Cont';

function App() {
  
  
  return (
    <Router>
          <Routes>
            <Route  path="/" element={ <Dashboard />}>
              <Route index exec={true} element={<Principal />} />
              <Route path="/index" element={<Principal />} />
              <Route path="/conten" element={<Cont />} />
            </Route>
          </Routes>
    </Router>

  );
}

export default App;
