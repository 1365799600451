import React from 'react'

export default function Footer() {
  return (
    <footer className="landing-footer bg-body footer-text ">
      <div className="footer-top">
        <div className="container">
          <div className="row gx-0 gy-4 g-md-5">
            <div className="col-lg-5">
              <a href="landing-page.html" className="app-brand-link mb-4">
                <span className="app-brand-logo demo">
                  <svg width={25} viewBox="0 0 25 42" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
                    <defs>
                      <path d="M13.7918663,0.358365126 L3.39788168,7.44174259 C0.566865006,9.69408886 -0.379795268,12.4788597 0.557900856,15.7960551 C0.68998853,16.2305145 1.09562888,17.7872135 3.12357076,19.2293357 C3.8146334,19.7207684 5.32369333,20.3834223 7.65075054,21.2172976 L7.59773219,21.2525164 L2.63468769,24.5493413 C0.445452254,26.3002124 0.0884951797,28.5083815 1.56381646,31.1738486 C2.83770406,32.8170431 5.20850219,33.2640127 7.09180128,32.5391577 C8.347334,32.0559211 11.4559176,30.0011079 16.4175519,26.3747182 C18.0338572,24.4997857 18.6973423,22.4544883 18.4080071,20.2388261 C17.963753,17.5346866 16.1776345,15.5799961 13.0496516,14.3747546 L10.9194936,13.4715819 L18.6192054,7.984237 L13.7918663,0.358365126 Z" id="path-1" />
                      <path d="M5.47320593,6.00457225 C4.05321814,8.216144 4.36334763,10.0722806 6.40359441,11.5729822 C8.61520715,12.571656 10.0999176,13.2171421 10.8577257,13.5094407 L15.5088241,14.433041 L18.6192054,7.984237 C15.5364148,3.11535317 13.9273018,0.573395879 13.7918663,0.358365126 C13.5790555,0.511491653 10.8061687,2.3935607 5.47320593,6.00457225 Z" id="path-3" />
                      <path d="M7.50063644,21.2294429 L12.3234468,23.3159332 C14.1688022,24.7579751 14.397098,26.4880487 13.008334,28.506154 C11.6195701,30.5242593 10.3099883,31.790241 9.07958868,32.3040991 C5.78142938,33.4346997 4.13234973,34 4.13234973,34 C4.13234973,34 2.75489982,33.0538207 2.37032616e-14,31.1614621 C-0.55822714,27.8186216 -0.55822714,26.0572515 -4.05231404e-15,25.8773518 C0.83734071,25.6075023 2.77988457,22.8248993 3.3049379,22.52991 C3.65497346,22.3332504 5.05353963,21.8997614 7.50063644,21.2294429 Z" id="path-4" />
                      <path d="M20.6,7.13333333 L25.6,13.8 C26.2627417,14.6836556 26.0836556,15.9372583 25.2,16.6 C24.8538077,16.8596443 24.4327404,17 24,17 L14,17 C12.8954305,17 12,16.1045695 12,15 C12,14.5672596 12.1403557,14.1461923 12.4,13.8 L17.4,7.13333333 C18.0627417,6.24967773 19.3163444,6.07059163 20.2,6.73333333 C20.3516113,6.84704183 20.4862915,6.981722 20.6,7.13333333 Z" id="path-5" />
                    </defs>
                    <g id="g-app-brand" stroke="none" strokeWidth={1} fill="none" fillRule="evenodd">
                      <g id="Brand-Logo" transform="translate(-27.000000, -15.000000)">
                        <g id="Icon" transform="translate(27.000000, 15.000000)">
                          <g id="Mask" transform="translate(0.000000, 8.000000)">
                            <mask id="mask-2" fill="white">
                              <use xlinkHref="#path-1" />
                            </mask>
                            <use fill="#696cff" xlinkHref="#path-1" />
                            <g id="Path-3" mask="url(#mask-2)">
                              <use fill="#696cff" xlinkHref="#path-3" />
                              <use fillOpacity="0.2" fill="#FFFFFF" xlinkHref="#path-3" />
                            </g>
                            <g id="Path-4" mask="url(#mask-2)">
                              <use fill="#696cff" xlinkHref="#path-4" />
                              <use fillOpacity="0.2" fill="#FFFFFF" xlinkHref="#path-4" />
                            </g>
                          </g>
                          <g id="Triangle" transform="translate(19.000000, 11.000000) rotate(-300.000000) translate(-19.000000, -11.000000) ">
                            <use fill="#696cff" xlinkHref="#path-5" />
                            <use fillOpacity="0.2" fill="#FFFFFF" xlinkHref="#path-5" />
                          </g>
                        </g>
                      </g>
                    </g>
                  </svg>
                </span>
                <span className="app-brand-text demo footer-link fw-bold ms-2 ps-1">UniverSystem</span>
              </a>
              <p className="footer-text footer-logo-description mb-4">
                Venta de productos tecnologicos, envios a nivel nacional gratis.
              </p>
              <form className="footer-form">
                <label htmlFor="footer-email" className="small">S</label>
                <div className="d-flex mt-1">
                  <input type="email" className="form-control rounded-0 rounded-start-bottom rounded-start-top" id="footer-email" placeholder="Your email" />
                  <button type="submit" className="btn btn-primary shadow-none rounded-0 rounded-end-bottom rounded-end-top">
                    Subscribe
                  </button>
                </div>
              </form>
            </div>
            <div className="col-lg-2 col-md-4 col-sm-6">
              <h6 className="footer-title mb-4">Demos</h6>
              <ul className="list-unstyled">
                <li className="mb-3">
                  <a href="../vertical-menu-template/" target="_blank" className="footer-link">Vertical Layout</a>
                </li>
                <li className="mb-3">
                  <a href="../horizontal-menu-template/" target="_blank" className="footer-link">Horizontal Layout</a>
                </li>
                <li className="mb-3">
                  <a href="../vertical-menu-template-bordered/" target="_blank" className="footer-link">Bordered Layout</a>
                </li>
                <li className="mb-3">
                  <a href="../vertical-menu-template-semi-dark/" target="_blank" className="footer-link">Semi Dark Layout</a>
                </li>
                <li className="mb-3">
                  <a href="../vertical-menu-template-dark/" target="_blank" className="footer-link">Dark Layout</a>
                </li>
              </ul>
            </div>
            <div className="col-lg-2 col-md-4 col-sm-6">
              <h6 className="footer-title mb-4">Pages</h6>
              <ul className="list-unstyled">
                <li className="mb-3">
                  <a href="pricing-page.html" className="footer-link">Pricing</a>
                </li>
                <li className="mb-3">
                  <a href="payment-page.html" className="footer-link">Payment<span className="badge rounded bg-primary ms-2 px-2">New</span></a>
                </li>
                <li className="mb-3">
                  <a href="checkout-page.html" className="footer-link">Checkout</a>
                </li>
                <li className="mb-3">
                  <a href="help-center-landing.html" className="footer-link">Help Center</a>
                </li>
                <li className="mb-3">
                  <a href="../vertical-menu-template/auth-login-cover.html" target="_blank" className="footer-link">Login/Register</a>
                </li>
              </ul>
            </div>
            <div className="col-lg-3 col-md-4">
              <h6 className="footer-title mb-4">Download our app</h6>
              <a href="javascript:void(0);" className="d-block footer-link mb-3 pb-2"><img src="../../assets/img/front-pages/landing-page/apple-icon.png" alt="apple icon" /></a>
              <a href="javascript:void(0);" className="d-block footer-link"><img src="../../assets/img/front-pages/landing-page/google-play-icon.png" alt="google play icon" /></a>
            </div>
          </div>
        </div>
      </div>
      <div className="footer-bottom py-3">
        <div className="container d-flex flex-wrap justify-content-between flex-md-row flex-column text-center text-md-start">
          <div className="mb-2 mb-md-0">
            <span className="footer-text">©
            </span>
            <a href="https://themeselection.com" target="_blank" className="fw-medium text-white footer-link">ThemeSelection,</a>
            <span className="footer-text"> Made with ❤️ for a better web.</span>
          </div>
          <div>
            <a href="https://github.com/themeselection" className="footer-link me-3" target="_blank">
              <img src="../../assets/img/front-pages/icons/github-light.png" alt="github icon" data-app-light-img="front-pages/icons/github-light.png" data-app-dark-img="front-pages/icons/github-dark.png" />
            </a>
            <a href="https://www.facebook.com/ThemeSelections/" className="footer-link me-3" target="_blank">
              <img src="../../assets/img/front-pages/icons/facebook-light.png" alt="facebook icon" data-app-light-img="front-pages/icons/facebook-light.png" data-app-dark-img="front-pages/icons/facebook-dark.png" />
            </a>
            <a href="https://twitter.com/Theme_Selection" className="footer-link me-3" target="_blank">
              <img src="../../assets/img/front-pages/icons/twitter-light.png" alt="twitter icon" data-app-light-img="front-pages/icons/twitter-light.png" data-app-dark-img="front-pages/icons/twitter-dark.png" />
            </a>
            <a href="https://www.instagram.com/themeselection/" className="footer-link" target="_blank">
              <img src="../../assets/img/front-pages/icons/instagram-light.png" alt="google icon" data-app-light-img="front-pages/icons/instagram-light.png" data-app-dark-img="front-pages/icons/instagram-dark.png" />
            </a>
          </div>
        </div>
      </div>
    </footer>
  )
}
