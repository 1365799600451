import React from 'react'
import { NavLink  } from 'react-router-dom';

export default function Nav() {
  return (
    <nav className="layout-navbar shadow-none py-0 ">
      <div className="container">
        <div className="navbar navbar-expand-lg landing-navbar px-3 px-md-4 ">
          {/* Menu logo wrapper: Start */}
          <div className="navbar-brand app-brand demo d-flex py-0 me-4">
            {/* Mobile menu toggle: Start*/}
            
            {/* Mobile menu toggle: End*/}
            <NavLink to="/" className="app-brand-link" >
              <span className="app-brand-logo demo">
                
              </span>
              <h4 className="app-brand-text menu-text fw-bold ms-0 ps-0 mb-1"><strong>UniverSystem</strong></h4>
            </NavLink>
            
          </div>
          {/* Menu logo wrapper: End */}
          {/* Menu wrapper: Start */}
          <div className="collapse navbar-collapse landing-nav-menu" id="navbarSupportedContent">
              <i className="app-brand-logo demo text-center fw-bolder ms-3 ">
                ¡La tienda en donde todo es barato! - Llamanos al +51 994 174 422
              </i>
          </div>
          <div className="landing-menu-overlay d-lg-none" />
          {/* Menu wrapper: End */}
          {/* Toolbar: Start */}
          <ul className="navbar-nav flex-row align-items-center ms-auto">
            {/* Style Switcher */}
            
            {/* / Style Switcher*/}
            {/* navbar button: Start */}
            <li>
              <button type="button" className="btn btn-icon rounded-pill btn-outline-success me-1">
                <i className="tf-icons bx bxl-whatsapp bx-22px"></i>
              </button>
            </li>
            <li>
              
              <button type="button" className="btn btn-icon rounded-pill btn-outline-facebook me-1">
                <i className="tf-icons bx bxl-facebook bx-22px "></i>
              </button> 
            </li>
            <li>
              <button type="button" className="btn btn-icon rounded-pill btn-outline-dark me-1">
                <i className="tf-icons bx bxl-tiktok bx-22px"></i>
              </button>
            </li>
            <li>
              <button type="button" className="btn btn-icon rounded-pill btn-outline-instagram me-1">
                <i className="tf-icons bx bxl-instagram bx-22px"></i>
              </button>
            </li>
            
            {/* navbar button: End */}
          </ul>
          {/* Toolbar: End */}
        </div>
      </div>
    </nav>
)
}
