import React, {useEffect} from 'react'
import { Outlet } from "react-router-dom"
import { setupTheme } from '../assets/js/front-main';
import Nav from '../layout/Nav'
import Header from '../layout/Header'
import Footer from '../layout/Footer'

export default function Dashboard(){
  useEffect(() => {
    setupTheme();
    // Resto del código...

  }, []);
  return (
    <>
      {/* Navbar: start */}
      <Nav />
      {/* Navbar: End */}
      {/* Sections:Start */}
      {/* Help Center Header: Start */}
      <Header />
      {/* Help Center Header: End */}
      <Outlet />
      {/* / Sections:End */}
      {/* Footer: Start */}
      
      <Footer />
      {/* Footer: End */}
      {/* <div className="buy-now">
        <a href="https://themeselection.com/item/sneat-bootstrap-html-admin-template/" target="_blank" className="btn btn-danger btn-buy-now">Buy Now</a>
      </div> */}
    </>
  )
}
