import React, {useState, useEffect} from 'react'
import { ToastContainer } from 'react-toastify';
import { notify_warning,swall_error} from '../components/utils';
import axios from 'axios';
import * as Apis from './Apis'
//import Swal from 'sweetalert2';
import '../App.css'
import $ from 'jquery'


const Principal = () => {
  const url_api = process.env.REACT_APP_API_BACK;
  //console.log("URLAPI", url_api);

  const [arrSearcPr, setArrSearcPr] = useState('TODOS')
  const [selectCate, setSelectCate] = useState([]) //PARA LISTAR LAS CATEGORIAS
  const [arrListaPr, setArrListaPr] = useState([]) //PARA LISTAR PRODUCTOS LA 

  const [catSlc, catSlcset] = useState(''); // Controlar desde dónde cargar
  const [offset, setOffset] = useState(0); // Controlar desde dónde cargar
  const [isLoading, setIsLoading] = useState(false); // Indicador de carga
  const [hasMore, setHasMore] = useState(true); // Determinar si hay más productos
  
  const showDataListaPrecios = async (category = '', currentOffset = 0) => {
    setIsLoading(true);
      try {
        const body = {
          limit: 20,
          offset:currentOffset,
          catSlc: category
        }
        const response = await Apis.apiListProd(body);
        setArrListaPr((prev) => [...prev, ...response])


        setOffset((prev) => prev + response.length);
        console.log("response.length", response.length);
        
        if (response.length < 10) {
          setHasMore(false); // Si se devuelven menos de 10, no hay más para cargar
        }
      } catch (error) {
        console.error("Error loading products:", error);
      } finally {
        setIsLoading(false);
      }
  }
  
  useEffect(() => {
    
    $("#template-customizer").addClass("d-none")
   
    const showData1 = async () => {
      //console.log(URLAPI+'/post-api-catalogoCategoriaHana');
      const apiCategoria = url_api+'/post-api-catalogoCategoriaHana'
      const response = await axios.post(apiCategoria);
      //console.log(datadtos);
      setSelectCate(response.data)
    }
    showData1()

    showDataListaPrecios()

    
  },[url_api])

 const onClickLimpiar = () =>{
  setOffset(0)
  setArrListaPr([])
  setHasMore(true)
  catSlcset('')
 }



  const onClickReturn= async ()=>{
    //setArrSlcbtnC(1)
    $("#carr").removeClass("d-none")
    $("#btn_retorno").addClass("d-none")
    $("#btn_categorias").removeClass("d-none")
    $("#btn_reS").addClass("d-none")
    window.scrollTo({ //para regresar a la parte superior de la página
      top: 0,
      behavior: 'smooth',
    });
  }




  const onClickSCategoria = async(event, index) => {
    onClickLimpiar()
    console.log("index.Code", index.Code);
    setArrSearcPr(index.Name?? 'TODOS')
    // Actualizar la categoría seleccionada
    catSlcset(index.Code);

    // Llamar a la función para recargar productos
    await showDataListaPrecios(index.Code, 0);
  }
 
  const onClickVerMas = async() => {
    await showDataListaPrecios(catSlc, offset);
  }




  return (
    <>
    
      {/* Popular Articles: Start */}
      <section className="section py-2">

        <div className="container-fluid">
        <div className="row mb-2">

          <div className="col-12 col-md-12 col-lg-12 text-center">
            <div className="btn-group">
              <button type="button" className="btn btn-sm btn-danger rounded-pill d-none" id="btn_retorno" alt="Regresar al Catalogo" onClick={onClickReturn} > <span className="bx bx-arrow-back"></span></button>
              <button  type="button" className="btn btn-md btn-danger rounded-pill dropdown-toggle hide-arrow" id="btn_categorias" data-bs-toggle="dropdown" aria-expanded="false">{arrSearcPr}<i className="bx bx-dots-vertical-rounded"></i></button>
              {/* <ul className="dropdown-menu dropdown-menu-end"  style={{"height": "300px", "overflowY": "scroll"}} > */}
              <ul className="dropdown-menu dropdown-menu-end custom-scroll" style={{"fontSize":"12px", "height": "300px", "overflowY": "scroll"}}  >
                  <li><button className="dropdown-item btn-sm fw-bold" 
                      onClick={ (event) => onClickSCategoria(event, "0")} >TODOS</button>
                  </li>
                  {                        
                    selectCate?.map((item,index) => {
                      return <li key={index}><button  className="dropdown-item btn-sm fw-bold " onClick={ (event) => onClickSCategoria(event, item)} >{item?.Name}</button></li>
                    })
                  }
              </ul>
            </div>
          </div>
        </div>
          <div className="row ">
            {
              
                  arrListaPr?.map((item,index) => {
                    //var val_img = (item?.ImgNom) ? "/arcatalogo/"+item?.ImgNom : logo_ca;
                    var val_img = url_api+"/imgProd?detProd=791187-min.jpg";
                    return( 
                      <div className="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-3 px-2 mb-3" key={index} >   
                        <div className="card border-2 h-100 py-2 align-items-center card-focus">
                          <div className="divImagen">
                            <button 
                              className="btn btn-sm btn-danger btn-focus" 
                              type="button"
                              data-bs-toggle="modal" data-bs-target="#mdlDetalleProducto" 
                            >
                              <i className="bx bx-show"></i>
                            </button>
                          
                            <img src={val_img} className="img-focus"  alt="UniverSystem"  />
                          </div>
                          <div className="card-body text-center py-1 mb-1">
                            <h6 className="fw-bold mb-0 text-dark" >{item?.Descripcion}</h6>
                            <span className="fw-bold text-success" style={{"fontSize":"10px"}}>Stock <b className="fw-bold text-dark">{Math.trunc(Math.round(item?.Stock).toFixed(2))}</b> <span className="fw-bold text-success" style={{"fontSize":"10px"}}>UNIDAD</span></span>
                            <div className="mb-1" >
                              <span className="fw-bold text-dark" >S/ </span>
                              <span className="fw-bold text-dark" > {(Math.round(item?.CostoReal).toFixed(2))} </span>
                              <span >| </span>
                              <s className="fw-bold text-dark" style={{"fontSize":"12px"}} >S/ 777.00</s>
                              {/* <s className="fw-bold text-dark" style={{"fontSize":"11px"}} >S/ {item?.Fin}</s> */}
                            </div>
                            <div className="row">
                              
                              <div className="col-12 px-0"> 
                                <button className="btn btn-sm rounded-pill btn-outline-success me-1" type="button"  ><i className='tf-icons bx bxl-whatsapp'></i></button>
                                <button className="btn btn-sm rounded-pill btn-outline-info me-1" type="button"  ><i className='tf-icons bx bxl-messenger'></i></button>
                                <button className="btn btn-sm rounded-pill btn-outline-instagram me-1" type="button"  ><i className='tf-icons bx bxl-instagram'></i></button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    )
                  })
                
            }
            
          </div>
          <div className="col-12 col-md-12 col-lg-12 text-center">
            {hasMore ? (
              <button className='btn btn-md rounded-pill btn-outline-dark' onClick={(e) => { onClickVerMas()}} disabled={isLoading}>
                {isLoading ? "Cargando..." : "Ver más"}
                &nbsp;&nbsp;&nbsp;
                <i className='bx bx-xs bx-plus-medical'></i>
              </button>
            ) : (
              <p>No hay más productos para mostrar</p>
            )}
          </div>
        </div>


        
        



        {/** Visualizar detalles producto */}
        <div className="modal fade " id="mdlDetalleProducto"  tabIndex="-1" aria-hidden="true" >
          <div className="modal-dialog modal-dialog-centered" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="modalCenterTitle">Partida <strong className="modal_cod_partida"></strong> - <strong className="modal_des_partida"></strong></h5>
                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" ></button>
              </div>
              <div className="modal-body">
              </div>
              <div className="modal-footer">
              </div>
            </div>
          </div>
        </div>

        {/** Visualizar detalles del pedidos - pago */}
      



      </section>
      <ToastContainer />
    </>
  )
}

export default Principal